import { initializeApp } from 'firebase/app'
import {
  browserLocalPersistence,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

export const firebaseConfig = {
  apiKey: 'AIzaSyBEeAhBk_k3cOhi9AqfZSrHtZhAnj5J8Ks',
  authDomain: 'praxis-bulkamp.firebaseapp.com',
  projectId: 'praxis-bulkamp',
  storageBucket: 'praxis-bulkamp.appspot.com',
  messagingSenderId: '74396546175',
  appId: '1:74396546175:web:57200ec57fe36401c6bb4a',
  measurementId: 'G-0D5BGBSHMD',
}

export const app = initializeApp(firebaseConfig)
export const auth = initializeAuth(app, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
})
export const firestore = getFirestore(app)

// export const getFirestoreInstance = async () => {
//   const { getFirestore } = await import('firebase/firestore')
//   return getFirestore(app)
// }
