import {
  isFriday,
  isMonday,
  isThursday,
  isTuesday,
  isWednesday,
  nextFriday,
  nextMonday,
  nextThursday,
  nextTuesday,
  nextWednesday,
  previousFriday,
  previousMonday,
  previousThursday,
  previousTuesday,
  previousWednesday,
} from 'date-fns'

export const today = new Date()

export const isDayMethods = [isMonday, isTuesday, isWednesday, isThursday, isFriday]
export const previousDayMethods = [
  previousMonday,
  previousTuesday,
  previousWednesday,
  previousThursday,
  previousFriday,
]
export const nextDayMethods = [nextMonday, nextTuesday, nextWednesday, nextThursday, nextFriday]

export const months = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
]
export const times = [
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30'
]
