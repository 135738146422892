import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface state {
  loadingTimes: boolean // Should the times component display a loading state
  selectedDate: Date | null // Date the user selected from the calendar
  availableTimes: Date[] | null // Times that are not reserved on the selecedDate
  verifyBooking: boolean // Prompt user to verify the booking action
  selectedTime: Date | null // Time the user selected on selectedDate
  maxAppointmentsOnDate: number | null
}

/*

This Slice handles the communication between the Calendar component and 
the available times the user can select from

*/

const initialState: state = {
  loadingTimes: false,
  selectedDate: null,
  availableTimes: null,
  verifyBooking: false,
  selectedTime: null,
  maxAppointmentsOnDate: null,
}

const calendarAndTimeSlice = createSlice({
  name: 'time',
  initialState,
  reducers: {
    loadingTimes: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingTimes = payload
    },
    setAvailableTimes: (state, { payload }: PayloadAction<Date[] | null>) => {
      state.availableTimes = payload
    },
    setSelectedDate: (state, { payload }: PayloadAction<Date | null>) => {
      state.selectedDate = payload
    },
    setVerifyBooking: (state, { payload }: PayloadAction<boolean>) => {
      state.verifyBooking = payload
    },
    setSelectedTime: (state, { payload }: PayloadAction<Date | null>) => {
      state.selectedTime = payload
    },
    setMaxAppointmentsOnDate: (state, { payload }: PayloadAction<number>) => {
      state.maxAppointmentsOnDate = payload
    },
  },
})

const calendarAndTimeReducer = calendarAndTimeSlice.reducer
export default calendarAndTimeReducer
export const {
  loadingTimes,
  setAvailableTimes,
  setSelectedDate,
  setVerifyBooking,
  setSelectedTime,
  setMaxAppointmentsOnDate,
} = calendarAndTimeSlice.actions
