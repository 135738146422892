import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit'
import appointmentReducer from './user/appointmentSlice'
import calendarAndTimeReducer from './calendarAndTimeSlice'
import dashboardActionsReducer from './dashboard/dashboardActionsSlice'
import adminReducer from './admin/adminSlice'

const rootReducer = combineReducers({
  appointment: appointmentReducer,
  calendarAndTime: calendarAndTimeReducer,
  dashboard: dashboardActionsReducer,
  admin: adminReducer,
})

// Disable warning messages for Dates in Redux Tookit
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
})

const store = configureStore({ reducer: rootReducer, middleware: customizedMiddleware })
export type RootState = ReturnType<typeof store.getState>
export default store
