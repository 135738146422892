import { User } from 'firebase/auth'
import { useState, useEffect } from 'react'
import { auth } from './firebaseConfig'

const useAuthListener = () => {
  const [loggedInUser, setLoggedInUser] = useState<null | User>()
  let parse: any
  useEffect(() => {
    setLoggedInUser(parse)
    // const auth = getAuth()
    const listener = auth.onAuthStateChanged((user) => {
      if (user) {
        const setCookie = async () => {
          const token = await user.getIdToken()

          // Set the JWT Cookie
          await fetch('/api/setCookie', {
            method: 'POST',
            body: JSON.stringify({ token }),
            headers: {
              'Content-Type': 'application/json',
            },
          })
        }

        setCookie()
        setLoggedInUser(user)
      } else {
        // Cookie Removal must be handled by handleSignout()
        setLoggedInUser(null)
      }
    })

    return () => listener()
  }, [])

  return loggedInUser
}

export default useAuthListener
