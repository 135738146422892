import { Disclosure, Menu, Transition } from '@headlessui/react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, forwardRef, Fragment, useContext } from 'react'
import { HiMenu, HiX } from 'react-icons/hi'
import { Link as ReactScrollLink } from 'react-scroll'
import { nav } from '../constants/nav'
import * as ROUTES from '../constants/routes'
import { AuthContext, UserContext } from '../lib/context'
import { classNames } from '../services'

interface NavbarProps {}

const Navbar: FC<NavbarProps> = ({}) => {
  // Hooks
  const router = useRouter()
  const user = useContext(UserContext)
  const auth = useContext(AuthContext)

  const currentPath = router.pathname
  const userIsOnHomepage = currentPath === ROUTES.HOMEPAGE
  const userIsOnSignup = currentPath === ROUTES.SIGNUP
  const userIsOnLogin = currentPath === ROUTES.LOGIN

  // For Tailwind Menu Integration With NextJS
  // eslint-disable-next-line react/display-name
  const MenuLink = forwardRef((props: any, ref) => {
    let { href, children, ...rest } = props
    return (
      <Link href={href} prefetch={false}>
        <a ref={ref} {...rest}>
          {children}
        </a>
      </Link>
    )
  })

  const signout = async () => {
    const { handleSignout } = await import('../services')
    handleSignout(auth)
  }

  return (
    <>
      <Disclosure as='nav' className='bg-white shadow fixed top-0 left-0 right-0 z-50'>
        {({ open, close }) => (
          <>
            <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
              <div className='flex justify-between h-16'>
                <div className='flex'>
                  <div className='-ml-2 mr-2 flex items-center md:hidden'>
                    {/* Mobile menu button */}
                    <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'>
                      <span className='sr-only'>Menü öffnen</span>
                      {open ? (
                        <HiX className='block h-6 w-6' aria-hidden='true' />
                      ) : (
                        <HiMenu className='block h-6 w-6' aria-hidden='true' />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className='flex items-center'>
                    <Link prefetch={false} passHref={true} href={ROUTES.HOMEPAGE}>
                      <a className='relative block lg:hidden h-8 w-8'>
                        <Image layout='fill' src='/logo-without-text.png' alt='Praxis am Bulkamp Logo' />
                      </a>
                    </Link>
                    <div className='hidden lg:flex items-center mr-2'>
                      <Link prefetch={false} passHref={true} href={ROUTES.HOMEPAGE}>
                        <a className='relative h-8 w-24'>
                          <Image
                            alt='Praxis am Bulkamp Logo'
                            width={584}
                            height={190}
                            layout='responsive'
                            src='/logo-with-text.png'
                            className='cursor-pointer'
                          />
                        </a>
                      </Link>
                    </div>
                  </div>

                  {/**
                   * Desktop nav items
                   */}

                  {!user && (
                    <div className='hidden md:ml-6 md:flex md:space-x-8'>
                      {nav.map((navItem) => (
                        <React.Fragment key={navItem.name}>
                          {userIsOnHomepage ? (
                            <ReactScrollLink
                              key={navItem.name}
                              href={navItem.name}
                              spy={true}
                              smooth={true}
                              duration={500}
                              activeClass='text-indigo-600 border-indigo-600'
                              to={navItem.name}
                              className='cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'>
                              {navItem.label}
                            </ReactScrollLink>
                          ) : (
                            <Link
                              prefetch={false}
                              passHref={true}
                              href={`${ROUTES.HOMEPAGE}#${navItem.name}`}
                              key={navItem.label}>
                              <a className='cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'>
                                {navItem.label}
                              </a>
                            </Link>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </div>
                <div className='flex items-center'>
                  <>
                    <div className='flex-shrink-0'>
                      {!user && (
                        <>
                          <Link prefetch={false} passHref={true} href={ROUTES.SIGNUP}>
                            <a className='hidden md:flex p-1 items-center text-sm text-gray-400 hover:text-gray-500'>
                              Account erstellen
                            </a>
                          </Link>
                          <Link prefetch={false} passHref={true} href={ROUTES.LOGIN}>
                            <a className='md:hidden relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                              <span>Anmelden</span>
                            </a>
                          </Link>
                        </>
                      )}
                    </div>
                    <div className='hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center'>
                      {!user && (
                        <Link prefetch={false} href={ROUTES.LOGIN}>
                          <a className='relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                            <span>Anmelden</span>
                          </a>
                        </Link>
                      )}

                      {/* Profile dropdown */}
                      {user && (
                        <>
                          <p className='text-xs font-medium text-gray-500 uppercase tracking-wider'>
                            {user.displayName}
                          </p>
                          <Menu as='div' className='ml-3 relative'>
                            <div>
                              <Menu.Button className='bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
                                <span className='sr-only'>Menü öffnen</span>
                                <div className='overflow-hidden h-8 w-8 rounded-full border border-white outline outline-1 outline-gray-500'>
                                  <Image src='/defaultuser.png' width={376} height={376} />
                                </div>
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter='transition ease-out duration-200'
                              enterFrom='transform opacity-0 scale-95'
                              enterTo='transform opacity-100 scale-100'
                              leave='transition ease-in duration-75'
                              leaveFrom='transform opacity-100 scale-100'
                              leaveTo='transform opacity-0 scale-95'>
                              <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                <Menu.Item>
                                  <MenuLink
                                    href={ROUTES.DASHBOARD}
                                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                                    Meine Termine
                                  </MenuLink>
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      type='button'
                                      onClick={signout}
                                      className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700 cursor-pointer w-full text-left'
                                      )}>
                                      Abmelden
                                    </button>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>

            <Disclosure.Panel className='md:hidden'>
              <div className='pt-2 pb-3 space-y-1'>
                {/**
                 * Mobile nav items
                 */}

                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                {nav.map((navItem) => (
                  <React.Fragment key={navItem.name}>
                    {userIsOnHomepage ? (
                      <ReactScrollLink
                        onClick={() => close()}
                        key={navItem.name}
                        spy={true}
                        smooth={true}
                        duration={500}
                        activeClass='text-indigo-700 bg-indigo-50 border-indigo-500'
                        to={navItem.name}
                        className='border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6'>
                        {navItem.label}
                      </ReactScrollLink>
                    ) : (
                      <Link prefetch={false} href={`${ROUTES.HOMEPAGE}#${navItem.name}`} key={navItem.label}>
                        <a className='border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6'>
                          {navItem.label}
                        </a>
                      </Link>
                    )}
                  </React.Fragment>
                ))}
              </div>
              {user ? (
                <div className='pt-4 pb-3 border-t border-gray-200'>
                  <div className='flex items-center px-4 sm:px-6'>
                    <div className='flex-shrink-0'>
                      <div className='overflow-hidden h-10 w-10 rounded-full border border-white outline outline-1 outline-gray-500'>
                        <Image src='/defaultuser.png' width={376} height={376} />
                      </div>
                    </div>
                    <div className='ml-3'>
                      <div className='text-base font-medium text-gray-800'>{user.displayName}</div>
                      <div className='text-sm font-medium text-gray-500'>{user.email}</div>
                    </div>
                  </div>
                  <div className='mt-3 space-y-1'>
                    <Disclosure.Button
                      as='a'
                      href={ROUTES.DASHBOARD}
                      className={`border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6 $ ${
                        currentPath === ROUTES.DASHBOARD && 'bg-indigo-50 border-indigo-500 text-indigo-700'
                      }`}>
                      Termin vereinbaren
                    </Disclosure.Button>
                    <button
                      type='button'
                      onClick={signout}
                      className='border-transparent w-full text-left text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6'>
                      Abmelden
                    </button>
                  </div>
                </div>
              ) : (
                <div className='pb-3 border-t border-gray-200'>
                  <div className='mt-3 space-y-1'>
                    <Disclosure.Button
                      as='a'
                      href={ROUTES.SIGNUP}
                      className={`border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6 ${
                        userIsOnSignup && 'bg-indigo-50 border-indigo-500 text-indigo-700'
                      }`}>
                      Account erstellen
                    </Disclosure.Button>
                    <Disclosure.Button
                      as='a'
                      href={ROUTES.LOGIN}
                      className={`border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6 ${
                        userIsOnLogin && 'bg-indigo-50 border-indigo-500 text-indigo-700'
                      }`}>
                      Anmelden
                    </Disclosure.Button>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}

export default Navbar
