import '../styles/globals.css'
import '../components/CalendarComponent/Calendar.css'
import type { AppProps } from 'next/app'
import Layout from '../components/Layout'
import { Toaster } from 'react-hot-toast'
import { AuthContext, UserContext } from '../lib/context'
import useAuthListener from '../lib/useAuthListener'
import { Provider } from 'react-redux'
import store from '../redux/store'
import Router from 'next/router'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import { auth } from '../lib/firebaseConfig'

function MyApp({ Component, pageProps }: AppProps) {
  const user = useAuthListener()

  //Binding events.
  Router.events.on('routeChangeStart', () => nprogress.start())
  Router.events.on('routeChangeComplete', () => nprogress.done())
  Router.events.on('routeChangeError', () => nprogress.done())

  return (
    <AuthContext.Provider value={auth}>
      <UserContext.Provider value={user}>
        <Provider store={store}>
          <Layout>
            <Component {...pageProps} />
            <Toaster />
          </Layout>
        </Provider>
      </UserContext.Provider>
    </AuthContext.Provider>
  )
}

export default MyApp
