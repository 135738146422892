import { add, differenceInMinutes, format, isBefore, isEqual, parse } from 'date-fns'
import { signOut } from 'firebase/auth'
import toast from 'react-hot-toast'
import { months, today } from '../constants/adminCalendar'
import { openinghours } from '../typings'

export const handleSignout = async (auth: any) => {
  try {
    await signOut(auth)

    // Invalidate Session Cookie
    await fetch('/api/removeCookie', {
      method: 'POST',
      body: JSON.stringify({ key: 'static_key' }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  } catch (error) {
    toast.error('Fehler beim Abmelden.')
  } finally {
    window.location.href = '/myaccount/login'
  }
}

export const getRange = (time: Date) => {
  const hour = time.getHours()
  let minutes = time.getMinutes().toString()
  if (minutes === '0') minutes = '00'

  const until = add(time, { minutes: 30 })
  const untilHour = until.getHours()
  let untilMinutes: string | number = until.getMinutes()
  if (untilMinutes === 0) untilMinutes = '00'
  return { current: `${hour}:${minutes}`, until: `${untilHour}:${untilMinutes}` }
}

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ')
}

export const getGermanWeekdayName = (englishWeekday: string) => {
  if (englishWeekday === 'Monday') return 'Montag'
  if (englishWeekday === 'Tuesday') return 'Dienstag'
  if (englishWeekday === 'Wednesday') return 'Mittwoch'
  if (englishWeekday === 'Thursday') return 'Donnerstag'
  if (englishWeekday === 'Friday') return 'Freitag'
  if (englishWeekday === 'Saturday') return 'Samstag'
  if (englishWeekday === 'Sunday') return 'Sonntag'
}

export const getGermanMonthName = (monthIndex: number) => {
  return months[monthIndex]
}

export const getAllPossibleTimesForDate = (e: Date, schedule: openinghours, appointmentDuration: number) => {
  const lowercaseWeekday = format(e, 'EEEE').toLowerCase() as
    | 'monday'
    | 'tuesday'
    | 'wednesday'
    | 'thursday'
    | 'friday'
  const weekdaySchedule = schedule[lowercaseWeekday]

  const opening = add(e, {
    hours: weekdaySchedule?.opening?.hour,
    minutes: weekdaySchedule?.opening?.minute,
  })

  let breakStart: undefined | Date
  if (weekdaySchedule?.breakStart) {
    breakStart = add(e, {
      hours: weekdaySchedule.breakStart?.hour,
      minutes: weekdaySchedule.breakStart?.minute,
    })
  }

  let breakEnd: undefined | Date
  if (weekdaySchedule?.breakEnd) {
    breakEnd = add(e, {
      hours: weekdaySchedule.breakEnd.hour,
      minutes: weekdaySchedule.breakEnd.minute,
    })
  }

  const closing = add(e, {
    hours: weekdaySchedule?.closing?.hour,
    minutes: weekdaySchedule?.closing?.minute,
  })

  // Array that will contain all possible times that a user can choose from, regardless of availability
  const allPossibleTimes: Date[] = []

  if (breakStart && breakEnd) {
    // absolute value of difference in minutes
    const minutesFromOpeningToBreak = Math.abs(differenceInMinutes(opening, breakStart))
    // round down the amount of appointments
    const appointmentsUntilBreak = Math.floor(minutesFromOpeningToBreak / appointmentDuration)

    const minutesFromBreakEndToClose = Math.abs(differenceInMinutes(breakEnd, closing))
    const appointmentsUntilClose = Math.floor(minutesFromBreakEndToClose / appointmentDuration)

    // Appointments in the morning time
    for (let i = 0; i <= appointmentsUntilBreak - 1; i++) {
      const currentTime = add(opening, { minutes: appointmentDuration * i })
      allPossibleTimes.push(currentTime)
    }

    // Appointments after break time
    for (let i = 0; i <= appointmentsUntilClose - 1; i++) {
      const currentTime = add(breakEnd!, { minutes: appointmentDuration * i })
      allPossibleTimes.push(currentTime)
    }

    return { allPossibleTimes, appointmentsUntilBreak, appointmentsUntilClose }
  } else if (opening && closing) {
    // how many minutes from opening to closing time
    const minutesFromopeningToClose = Math.abs(differenceInMinutes(opening, closing))
    // how many appointments can be held from opening to closing time
    const appointmentsUntilClose = Math.floor(minutesFromopeningToClose / appointmentDuration)

    for (let i = 0; i <= appointmentsUntilClose - 1; i++) {
      const currentTime = add(opening, { minutes: appointmentDuration * i })
      allPossibleTimes.push(currentTime)
    }

    return { allPossibleTimes, appointmentsUntilClose }
  }
}

export function titleCase(str: string) {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

/**
 * @param {Date} startTime Time at which array starts
 * @param {Date} endTime Time at which array ends (inclusive)
 * @param {number} interval Interval to increment by
 */

export const generateTimesArray = (startTime: Date, endTime: Date, interval: number) => {
  const timeArr: string[] = []
  let currTime = startTime
  while (isBefore(currTime, endTime) || isEqual(currTime, endTime)) {
    const timeFormatted = format(currTime, 'kk:mm')
    timeArr.push(timeFormatted)
    currTime = add(currTime, { minutes: interval })
  }

  return timeArr
}

export const generateTimeStr = (hour: number, minute?: number) => {
  if (!minute) {
    return `${hour.toString().padStart(2, '0')}:00`
  } else {
    return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
  }
}

/**
 *
 * @param {string} time - string to convert to date in format '08:00'
 * @returns The passed time as a Date
 */

export const convertTimeToDate = (time: string) => {
  const dateFromTime = parse(time, 'kk:mm', today)
  return dateFromTime
}

// export const redirectUser = (isAdmin: boolean, router: NextRouter) => {
//   if (isAdmin === true) {
//     router.push('/admin')
//     return
//   } else {
//     router.push(ROUTES.DASHBOARD)
//   }

// }

export const getStepAnimationProps = (motionDirection: number) => {
  return {
    animate: 'enter',
    initial: 'initial',
    exit: 'exit',
    variants: {
      initial: {
        opacity: 0,
        x: motionDirection * 50,
      },
      enter: {
        opacity: 1,
        x: 0,
      },
      exit: {
        opacity: 0,
        x: motionDirection * -50,
      },
    },
  }
}
