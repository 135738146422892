import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { adminAppointment, appointment, appointmentDetail } from '../../typings'

const adminAppointments: adminAppointment[] = []
const appointmentDetails: appointmentDetail = {
  show: false,
  appointments: null,
}

const adminSlice = createSlice({
  name: 'appointmentMaker',
  initialState: {
    appointmentMaker: {
      name: '',
    },
    adminAppointments, // Store how which appointments are held in each timeslot
    appointmentDetails, // User clicked on an appointment, wants to see who has appointments for this timeslot
  },
  reducers: {
    setName: (state, { payload }) => {
      state.appointmentMaker.name = payload
    },
    setAdminAppointments: (state, { payload }: PayloadAction<adminAppointment[]>) => {
      state.adminAppointments = payload
    },
    openAppointmentDetails: (
      state,
      { payload }: PayloadAction<appointment[]>
    ) => {
      state.appointmentDetails.show = true
      state.appointmentDetails.appointments = payload
    },
    closeAppointmentDetails: (state) => {
      state.appointmentDetails.show = false
    },
  },
})

const adminReducer = adminSlice.reducer
export default adminReducer
export const { setName, setAdminAppointments, openAppointmentDetails, closeAppointmentDetails } =
  adminSlice.actions
