export const nav = [
  {
    name: 'hero',
    label: 'Home',
  },
  {
    name: 'services',
    label: 'Leistungen',
  },
  {
    name: 'office',
    label: 'Praxis',
  },
  {
    name: 'testimonial',
    label: 'Rezension',
  },
]
