import { useRouter } from 'next/router'
import { FC } from 'react'
import Navbar from './Navbar'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const router = useRouter()

  return (
    <>
      {!router.pathname.includes('admin') && <Navbar />}
      {children}
    </>
  )
}

export default Layout
