import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface state {
  editAppointment: {
    isBeingEdited: boolean // Is user editing the appointment
    docId: string | null // docId of the appointment being edited
  }
  cancelAppointment: {
    isBeingCancelled: boolean // Is user cancelling the appointment
    docId: string | null // docId of the appointment being cancelled
    date: null | string // 19.05.2022
  }
  delayAppointment: boolean
  inactiveAppointments: {
    show: boolean
    loading: boolean
  }
  currentPage: number
}

/*

This Slice handles the all actions that can be taken inside the User Dashboard

*/

const initialState: state = {
  editAppointment: {
    isBeingEdited: false,
    docId: null,
  },
  cancelAppointment: {
    isBeingCancelled: false,
    docId: null,
    date: null,
  },
  delayAppointment: false,
  inactiveAppointments: {
    show: false,
    loading: false
  },
  currentPage: 0,
}

const dashboardActionsSlice = createSlice({
  name: 'dashboardActions',
  initialState,
  reducers: {
    setEditAppointment: (
      state,
      { payload }: PayloadAction<{ isBeingEdited: boolean; docId: string | null }>
    ) => {
      state.editAppointment.isBeingEdited = payload.isBeingEdited
      state.editAppointment.docId = payload.docId
    },
    setCancelAppointment: (
      state,
      { payload }: PayloadAction<{ isBeingCancelled: boolean; docId: string | null; date: string | null }>
    ) => {
      state.cancelAppointment.isBeingCancelled = payload.isBeingCancelled
      state.cancelAppointment.docId = payload.docId
      state.cancelAppointment.date = payload.date
    },
    setDelayAppointment: (state, { payload }: PayloadAction<boolean>) => {
      state.delayAppointment = payload
    },
    setShowInactiveAppointments: (state, { payload }: PayloadAction<boolean>) => {
      state.inactiveAppointments.show = payload
    },
    setLoadingInactiveAppointments: (state, { payload }: PayloadAction<boolean>) => {
      state.inactiveAppointments.loading = payload
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload
    },
  },
})

const dashboardActionsReducer = dashboardActionsSlice.reducer
export default dashboardActionsReducer
export const {
  setEditAppointment,
  setDelayAppointment,
  setCancelAppointment,
  setCurrentPage,
  setShowInactiveAppointments,
  setLoadingInactiveAppointments
} = dashboardActionsSlice.actions
