import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: state = {
  isMakingAppointment: false,
  action: 'newAppointment',
  docId: null,
  oldDate: null,
  expectingData: null,
}

interface state {
  isMakingAppointment: boolean
  action: string
  docId: null | string
  oldDate: null | string

  expectingData: null | boolean
}

interface delayAppointment {
  action: string
  docId: string
  oldDate: string
}

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    toggleAppointment: (state) => {
      state.isMakingAppointment = !state.isMakingAppointment
      state.action = 'newAppointment'
    },
    delayAppointment: (state, { payload }: PayloadAction<delayAppointment>) => {
      state.action = payload.action
      state.isMakingAppointment = !state.isMakingAppointment
      state.docId = payload.docId
      state.oldDate = payload.oldDate
    },

    setExpectingData: (state, { payload }: PayloadAction<boolean>) => {
      state.expectingData = payload
    },
  },
})

const appointmentReducer = appointmentSlice.reducer
export default appointmentReducer
export const { toggleAppointment, delayAppointment, setExpectingData } = appointmentSlice.actions
